import { ITextFieldProps, Label, TextField } from "@fluentui/react";
import { Component } from "react";
import "./TextFieldWithVisualLimit.scss";

export interface ITextFieldWithVisualLimitProps extends ITextFieldProps {
    labelText: string;
    charLimit: number;
    remainingChars: number;
}

class TextFieldWithVisualLimit extends Component<ITextFieldWithVisualLimitProps> {
    render() {
        return (
            <div>
                <Label className={this.props.className}>{this.props.labelText}</Label>
                <span className="remainingCharsText">({this.props.remainingChars} characters remaining)</span>
                <TextField {...this.props} />
            </div>
        );
    }
}

export default TextFieldWithVisualLimit;