import { PublicClientApplication, Configuration, SilentRequest } from "@azure/msal-browser"

export const configuration: Configuration = {
    auth: {
        clientId: (process.env.REACT_APP_AUTH_CLIENT_ID as string),
        authority: (process.env.REACT_APP_AUTH_AUTHORITY as string),
        knownAuthorities: [(process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES as string)]
    }
}

export const scopes = [
    'openid',
    'profile',
    (process.env.REACT_APP_AUTH_SCOPE as string)
];

class AuthConfig
{
    public app: PublicClientApplication;
    public scopes = scopes;

    constructor()
    {
        this.app = new PublicClientApplication(configuration);
    }

    public async acquireTokenSilent()
    {
        const activeAccount = this.app.getActiveAccount();
        const accounts = this.app.getAllAccounts();

        if(!activeAccount && accounts.length === 0) {
            // User is not signed in.
            throw new Error('Auth.acquireTokenSilent() error: User is not signed in');
        }

        const request: SilentRequest = {
            scopes: this.scopes,
            account: activeAccount || accounts[0]
        };

        const authResult = await this.app.acquireTokenSilent(request);

        return authResult;
    }
}

const Auth = new AuthConfig();
export default Auth;