import React from 'react';
import AbILogo from '../assets/AbILogo.png';
import AbSPORULogo from '../assets/AbSPORULogo.png';
import ACRCLogo from '../assets/ACRCLogo.png';
import CTALogo from '../assets/ClinicalTrialsLogo.png';

export const Header: React.FunctionComponent = (props: any) => {

    return (
        <header className="App-header">
            <img src={AbILogo} alt="logo" />
            <img src={AbSPORULogo} alt="logo" />
            <img src={ACRCLogo} alt="logo" />
            <img src={CTALogo} alt="logo" />
        </header>
    );
}

export default Header;