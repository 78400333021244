import React from 'react';
import abIlogo from '../assets/AbILogoStacked.png';

export const Footer: React.FunctionComponent = (props: any) => {
    return (
        <footer className="App-footer">
            <img src={abIlogo} alt="ABI logo" />
        </footer>
    );
}

export default Footer;