import React from 'react';
import './App.scss';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import FormServiceRequest from './pages/FormServiceRequest';
import FormLetterSupport from './pages/FormLetterSupport';
import FormARECCISecondOpinion from './pages/FormARECCISecondOpinion';
import FormCovenantHealthRequest from './pages/FormCovenantHealthRequest';
import FormACRCCTAConciergeIntake from './pages/FormACRCCTAConciergeIntake';
import FormDirectoryIntake from './pages/FormDirectoryIntake';
import Logout from './pages/auth/Logout';
import { DefaultButton } from '@fluentui/react';
import usePageTracking from './hooks/usePageTracking';

export const App: React.FunctionComponent = (props: any) => {
    const history = useHistory();
    usePageTracking();
    return (
        <div className="App">
            <Header />
            <AuthenticatedTemplate>
                <BrowserRouter>
                    <Switch>
                        <Route path="/FormServiceRequest/:id?" component={FormServiceRequest} />
                        <Route path="/FormLetterSupport/:id?" component={FormLetterSupport} />
                        <Route path="/FormARECCISecondOpinion/:id?" component={FormARECCISecondOpinion} />
                        <Route path="/FormCovenantHealthRequest/:id?" component={FormCovenantHealthRequest} />
                        <Route path="/FormACRCCTAConciergeIntake/:id?" component={FormACRCCTAConciergeIntake} />
                        <Route path="/FormDirectoryIntake/:id?" component={FormDirectoryIntake} />
                        <Route path="/logout" exact component={Logout} />
                        <Route path="/" exact component={Dashboard} />
                    </Switch>
                </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <main className="App-navigation">
                    <div className="App-textInfo">
                        <h1>
                            Welcome!
                            <br /><br />
                            Please login to your existing account or create an account to access one of the following online request forms:
                        </h1>
                        <div style={{ marginBottom: 30, display: 'grid', gridTemplateColumns: 'auto 650px auto', alignItems: 'end' }}>
                            <div></div>
                            <div>
                                <h2>
                                    Alberta SPOR SUPPORT UNIT (AbSPORU)
                                    <ul>
                                        <li>AbSPORU Service Request</li>
                                        <li>AbSPORU Letter of Support Request</li>
                                    </ul>
                                    <br />Alberta Clinical Research Consortium (ACRC) / Clinical Trials Alberta (CTA)
                                    <ul>
                                        <li>ACRC/CTA Concierge Intake</li>
                                    </ul>
                                    {/* <br />A Project Ethics Consensus Community Initiative (ARECCI)
                                    <ul>
                                        <li>ARECCI Second Opinion Review Request</li>
                                    </ul> */}
                                    {/* <br />Covenant
                                    <ul>
                                        <li>Covenant Request</li>
                                    </ul> */}
                                </h2>
                            </div>
                        </div>
                        <div>
                                <h3>
                                    <br />
                                    AI office hours: Mon - Fri  8:15 - 4:30pm (except holidays). If you have any technical issues, kindly email, <a href='mailto:RPANotifications@albertainnovates.ca'>RPANotifications@albertainnovates.ca</a>
                                    <br />
                                </h3>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}><DefaultButton text="Login" onClick={() => { history.push('/login'); }} /></div>
                </main>
            </UnauthenticatedTemplate>
            <Footer />
        </div>
    );
}

export default App;
