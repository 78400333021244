import { Checkbox, ICheckboxProps, ICheckboxStyleProps, ICheckboxStyles } from "@fluentui/react";
import { Component } from "react";
import "./ErrorMessage.scss";

export interface ICheckboxWithValidationProps extends ICheckboxProps {
    errorMessage: string;
}

class CheckboxWithValidation extends Component<ICheckboxWithValidationProps> {
    render() {
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = {
                checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        return (
            <div>
                <Checkbox styles={checkboxStyles} {...this.props} />
                <div role="alert">
                    <p className="ms-TextField-errorMessage">
                        <span data-automation-id="error-message">{this.props.errorMessage}</span>
                    </p>
                </div>
            </div>
        );
    }
}

export default CheckboxWithValidation;