import { ChoiceGroup, IChoiceGroupProps } from "@fluentui/react";
import { Component } from "react";
import "./ErrorMessage.scss";

export interface IChoiceGroupWithValidationProps extends IChoiceGroupProps {
    errorMessage: string;
}

class ChoiceGroupWithValidation extends Component<IChoiceGroupWithValidationProps> {
    render() {
        return (
            <div>
                <ChoiceGroup {...this.props} />
                <div role="alert">
                    <p className="ms-TextField-errorMessage">
                        <span data-automation-id="error-message">{this.props.errorMessage}</span>
                    </p>
                </div>
            </div>
        );
    }
}

export default ChoiceGroupWithValidation;