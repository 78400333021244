import {Form} from './Form';

export class FormARECCISecondOpinionResource extends Form {
    public UserFirstName: string = '';
    public UserLastName: string = '';
    public UserEmail: string = '';
    public UserPhoneNumber: string = '';
    public submissionDate: Date | undefined = undefined;
    public projectSponsor: string = '';
    public projectSponsorEmail: string = '';
    public projectSponsorOrganization: string = '';
    public projectManager: string = '';
    public projectManagerPosition: string = '';
    public projectManagerPhone: string = '';
    public projectManagerEmail: string = '';
    public projectTitle: string = '';
    public projectPurpose: string = '';
    public projectType: string[] = [];
    public projectTypeOther: string = '';
    public projectSite: string = '';
    public targetStartDate: Date | undefined = undefined;
    public targetCompletionDate: Date | undefined = undefined;
    public ARECCIScreeningScore: string = '';
    public areasEthicalConcern: string = '';
    public attachedDocumentsInfo: string[] = [];

    public static fromJson(dataItem: any) {
        let item = new FormARECCISecondOpinionResource(dataItem.applicationId);
        item.amendmentId = dataItem.amendmentId ? dataItem.amendmentId : '';
        item.edgeId = dataItem.edgeId ? dataItem.edgeId : '';
        item.userId = dataItem.userId ? dataItem.userId : '';
        item.status = dataItem.status ? dataItem.status : '';
        item.UserFirstName = dataItem.UserFirstName ? dataItem.UserFirstName : '';
        item.UserLastName = dataItem.UserLastName ? dataItem.UserLastName : '';
        item.UserEmail = dataItem.UserEmail ? dataItem.UserEmail : '';
        item.UserPhoneNumber = dataItem.UserPhoneNumber ? dataItem.UserPhoneNumber : '';
        item.submissionDate = dataItem.submissionDate ? new Date(dataItem.submissionDate) : undefined;
        item.projectSponsor = dataItem.projectSponsor ? dataItem.projectSponsor : '';
        item.projectSponsorEmail = dataItem.projectSponsorEmail ? dataItem.projectSponsorEmail : '';
        item.projectSponsorOrganization = dataItem.projectSponsorOrganization ? dataItem.projectSponsorOrganization : '';
        item.projectManager = dataItem.projectManager ? dataItem.projectManager : '';
        item.projectManagerPosition = dataItem.projectManagerPosition ? dataItem.projectManagerPosition : '';
        item.projectManagerPhone = dataItem.projectManagerPhone ? dataItem.projectManagerPhone : '';
        item.projectManagerEmail = dataItem.projectManagerEmail ? dataItem.projectManagerEmail : '';
        item.projectTitle = dataItem.projectTitle ? dataItem.projectTitle : '';
        item.projectPurpose = dataItem.projectPurpose ? dataItem.projectPurpose : '';
        item.projectType = dataItem.projectType ? dataItem.projectType : [];
        item.projectTypeOther = dataItem.projectTypeOther ? dataItem.projectTypeOther : '';
        item.projectSite = dataItem.projectSite ? dataItem.projectSite : '';
        item.targetStartDate = dataItem.targetStartDate ? new Date(dataItem.targetStartDate) : undefined;
        item.targetCompletionDate = dataItem.targetCompletionDate ? new Date(dataItem.targetCompletionDate) : undefined;
        item.ARECCIScreeningScore = dataItem.ARECCIScreeningScore ? dataItem.ARECCIScreeningScore : '';
        item.areasEthicalConcern = dataItem.areasEthicalConcern ? dataItem.areasEthicalConcern : '';
        item.attachedDocumentsInfo = dataItem.attachedDocumentsInfo ? dataItem.attachedDocumentsInfo : [];
        item.additionalComments = dataItem.additionalComments ? dataItem.additionalComments : '';
        item.rejectionComments = dataItem.rejectionComments ? dataItem.rejectionComments : '';
        item.submittedDate = dataItem.submittedDate ? new Date(dataItem.submittedDate) : undefined;
        item.rejectedDate = dataItem.rejectedDate ? new Date(dataItem.rejectedDate) : undefined;
        item.approvedDate = dataItem.approvedDate ? new Date(dataItem.approvedDate) : undefined;
        item.notificationSendDate = dataItem.notificationSendDate ? new Date(dataItem.notificationSendDate) : undefined;
        item.Created = dataItem.Created ? new Date(dataItem.Created) : undefined;
        item.Modified = dataItem.Modified ? new Date(dataItem.Modified) : undefined;
        return item;
    }

    public static toJson(item: FormARECCISecondOpinionResource, newEntry: boolean) {
        let dataItem = {
            ...(newEntry && item.applicationId && {applicationId: item.applicationId}),
            ...(newEntry && item.amendmentId && {amendmentId: item.amendmentId}),
            ...(newEntry && item.userId && {userId: item.userId}),
            UserFirstName: item.UserFirstName,
            UserLastName: item.UserLastName,
            UserEmail: item.UserEmail,
            UserPhoneNumber: item.UserPhoneNumber,
            submissionDate: item.submissionDate ? item.submissionDate.toISOString() : null,
            projectSponsor: item.projectSponsor,
            projectSponsorEmail: item.projectSponsorEmail,
            projectSponsorOrganization: item.projectSponsorOrganization,
            projectManager: item.projectManager,
            projectManagerPosition: item.projectManagerPosition,
            projectManagerPhone: item.projectManagerPhone,
            projectManagerEmail: item.projectManagerEmail,
            projectTitle: item.projectTitle,
            projectPurpose: item.projectPurpose,
            projectType: item.projectType,
            projectTypeOther: item.projectTypeOther,
            projectSite: item.projectSite,
            targetStartDate: item.targetStartDate ? item.targetStartDate.toISOString() : null,
            targetCompletionDate: item.targetCompletionDate ? item.targetCompletionDate.toISOString() : null,
            ARECCIScreeningScore: item.ARECCIScreeningScore,
            areasEthicalConcern: item.areasEthicalConcern,
            attachedDocumentsInfo: item.attachedDocumentsInfo,
            additionalComments: item.additionalComments,
            rejectionComments: item.rejectionComments,
        };
        return dataItem;
    }
}
