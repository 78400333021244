import {Form} from './Form';

export class FormDirectoryIntakeResource extends Form {
    public IntakeUserName: string = '';
    public UserTitle: string = '';
    public UserCompany: string = '';
    public UserDepartment: string = '';
    public UserEmail: string = '';
    public UserPhoneNumber: string = '';
    public role: string = '';
    public roleOther: string = '';
    public specialityArea: string[] = [];
    public specialityAreaCancerOther: string = '';
    public specialityAreaOther: string = '';
    public topics: string = '';
    public researchType: string = '';
    public researchTypeOther: string = '';
    public ORCIDLinkedIn: string = '';
    public covenantHealthPrivileges: string = '';
    public CoPInfo: string[] = [];
    public CoPInfoOther: string = '';

    public static fromJson(dataItem: any) {
        let item = new FormDirectoryIntakeResource(dataItem.applicationId);
        item.amendmentId = dataItem.amendmentId ? dataItem.amendmentId : '';
        item.edgeId = dataItem.edgeId ? dataItem.edgeId : '';
        item.userId = dataItem.userId ? dataItem.userId : '';
        item.status = dataItem.status ? dataItem.status : '';
        item.IntakeUserName = dataItem.IntakeUserName ? dataItem.IntakeUserName : '';
        item.UserTitle = dataItem.UserTitle ? dataItem.UserTitle : '';
        item.UserCompany = dataItem.UserCompany ? dataItem.UserCompany : '';
        item.UserDepartment = dataItem.UserDepartment ? dataItem.UserDepartment : '';
        item.UserEmail = dataItem.UserEmail ? dataItem.UserEmail : '';
        item.UserPhoneNumber = dataItem.UserPhoneNumber ? dataItem.UserPhoneNumber : '';
        item.role = dataItem.role ? dataItem.role : '';
        item.roleOther = dataItem.roleOther ? dataItem.roleOther : '';
        item.specialityArea = dataItem.specialityArea ? dataItem.specialityArea : [];
        item.specialityAreaCancerOther = dataItem.specialityAreaCancerOther ? dataItem.specialityAreaCancerOther : '';
        item.specialityAreaOther = dataItem.specialityAreaOther ? dataItem.specialityAreaOther : '';
        item.topics = dataItem.topics ? dataItem.topics : '';
        item.researchType = dataItem.researchType ? dataItem.researchType : '';
        item.researchTypeOther = dataItem.researchTypeOther ? dataItem.researchTypeOther : '';
        item.ORCIDLinkedIn = dataItem.ORCIDLinkedIn ? dataItem.ORCIDLinkedIn : '';
        item.covenantHealthPrivileges = dataItem.covenantHealthPrivileges ? dataItem.covenantHealthPrivileges : '';
        item.CoPInfo = dataItem.CoPInfo ? dataItem.CoPInfo : [];
        item.CoPInfoOther = dataItem.CoPInfoOther ? dataItem.CoPInfoOther : '';
        item.additionalComments = dataItem.additionalComments ? dataItem.additionalComments : '';
        item.rejectionComments = dataItem.rejectionComments ? dataItem.rejectionComments : '';
        item.submittedDate = dataItem.submittedDate ? new Date(dataItem.submittedDate) : undefined;
        item.rejectedDate = dataItem.rejectedDate ? new Date(dataItem.rejectedDate) : undefined;
        item.approvedDate = dataItem.approvedDate ? new Date(dataItem.approvedDate) : undefined;
        item.notificationSendDate = dataItem.notificationSendDate ? new Date(dataItem.notificationSendDate) : undefined;
        item.Created = dataItem.Created ? new Date(dataItem.Created) : undefined;
        item.Modified = dataItem.Modified ? new Date(dataItem.Modified) : undefined;
        return item;
    }

    public static toJson(item: FormDirectoryIntakeResource, newEntry: boolean) {
        let dataItem = {
            ...(newEntry && item.applicationId && {applicationId: item.applicationId}),
            ...(newEntry && item.amendmentId && {amendmentId: item.amendmentId}),
            ...(newEntry && item.userId && {userId: item.userId}),
            IntakeUserName: item.IntakeUserName,
            UserTitle: item.UserTitle,
            UserCompany: item.UserCompany,
            UserDepartment: item.UserDepartment,
            UserEmail: item.UserEmail,
            UserPhoneNumber: item.UserPhoneNumber,
            role: item.role,
            roleOther: item.roleOther,
            specialityArea: item.specialityArea,
            specialityAreaCancerOther: item.specialityAreaCancerOther,
            specialityAreaOther: item.specialityAreaOther,
            topics: item.topics,
            researchType: item.researchType,
            researchTypeOther: item.researchTypeOther,
            ORCIDLinkedIn: item.ORCIDLinkedIn,
            covenantHealthPrivileges: item.covenantHealthPrivileges,
            CoPInfo: item.CoPInfo,
            CoPInfoOther: item.CoPInfoOther,
            additionalComments: item.additionalComments,
            rejectionComments: item.rejectionComments,
        };
        return dataItem;
    }
}
