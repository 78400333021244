import {Form} from './Form';

export class FormServiceRequestResource extends Form {
    public UserFirstName: string = '';
    public UserLastName: string = '';
    public UserEmail: string = '';
    public UserPhoneNumber: string = '';
    public requesterName: string = '';
    public requesterPhone: string = '';
    public requesterEmail: string = '';
    public requesterInstitution: string = '';
    public requesterDepartment: string = '';
    public applicantName: string = '';
    public applicantPhone: string = '';
    public applicantEmail: string = '';
    public applicantInstitution: string = '';
    public applicantDepartment: string = '';
    public role: string = '';
    public roleOther: string = '';
    public previousInteraction: string = '';
    public previousInteractionWho: string = '';
    public studyLongTitle: string = '';
    public studyShortTitle: string = '';
    public studyType: string[] = [];
    public ethicsApproval: string = '';
    public ethicsApplicationNumber: string = '';
    public primarySite: string = '';
    public targetStartDate: Date | undefined = undefined;
    public targetCompletionDate: Date | undefined = undefined;
    public projectFunded: string[] = [];
    public projectFundedInitiatives: string[] = [];
    public strategicClinicalNetwork: string[] = [];
    public nationalSPORNetwork: string[] = [];
    public SPORSupportUnit: string[] = [];
    public EDIPriorityArea: string[] = [];
    public EDIPriorityAreaParticularGroup: string = '';
    public layAbstract: string = '';
    public potentialImpact: string = '';
    public supportRequestedDescription: string = '';
    public supportRequestedAssistance: string[] = [];
    public attachedDocumentsInfo: string[] = [];
    public receiveNewsletter: string = '';

    public static fromJson(dataItem: any) {
        let item = new FormServiceRequestResource(dataItem.applicationId);
        item.amendmentId = dataItem.amendmentId ? dataItem.amendmentId : '';
        item.edgeId = dataItem.edgeId ? dataItem.edgeId : '';
        item.userId = dataItem.userId ? dataItem.userId : '';
        item.status = dataItem.status ? dataItem.status : '';
        item.UserFirstName = dataItem.UserFirstName ? dataItem.UserFirstName : '';
        item.UserLastName = dataItem.UserLastName ? dataItem.UserLastName : '';
        item.UserEmail = dataItem.UserEmail ? dataItem.UserEmail : '';
        item.UserPhoneNumber = dataItem.UserPhoneNumber ? dataItem.UserPhoneNumber : '';
        item.requesterName = dataItem.requesterName ? dataItem.requesterName : '';
        item.requesterPhone = dataItem.requesterPhone ? dataItem.requesterPhone : '';
        item.requesterEmail = dataItem.requesterEmail ? dataItem.requesterEmail : '';
        item.requesterInstitution = dataItem.requesterInstitution ? dataItem.requesterInstitution : '';
        item.requesterDepartment = dataItem.requesterDepartment ? dataItem.requesterDepartment : '';
        item.applicantName = dataItem.applicantName ? dataItem.applicantName : '';
        item.applicantPhone = dataItem.applicantPhone ? dataItem.applicantPhone : '';
        item.applicantEmail = dataItem.applicantEmail ? dataItem.applicantEmail : '';
        item.applicantInstitution = dataItem.applicantInstitution ? dataItem.applicantInstitution : '';
        item.applicantDepartment = dataItem.applicantDepartment ? dataItem.applicantDepartment : '';
        item.role = dataItem.role ? dataItem.role : '';
        item.roleOther = dataItem.roleOther ? dataItem.roleOther : '';
        item.previousInteraction = dataItem.previousInteraction ? dataItem.previousInteraction : '';
        item.previousInteractionWho = dataItem.previousInteractionWho ? dataItem.previousInteractionWho : '';
        item.studyLongTitle = dataItem.studyLongTitle ? dataItem.studyLongTitle : '';
        item.studyShortTitle = dataItem.studyShortTitle ? dataItem.studyShortTitle : '';
        item.studyType = dataItem.studyType ? dataItem.studyType : [];
        item.ethicsApproval = dataItem.ethicsApproval ? dataItem.ethicsApproval : '';
        item.ethicsApplicationNumber = dataItem.ethicsApplicationNumber ? dataItem.ethicsApplicationNumber : '';
        item.primarySite = dataItem.primarySite ? dataItem.primarySite : '';
        item.targetStartDate = dataItem.targetStartDate ? new Date(dataItem.targetStartDate) : undefined;
        item.targetCompletionDate = dataItem.targetCompletionDate ? new Date(dataItem.targetCompletionDate) : undefined;
        item.projectFunded = dataItem.projectFunded ? dataItem.projectFunded : [];
        item.projectFundedInitiatives = dataItem.projectFundedInitiatives ? dataItem.projectFundedInitiatives : [];
        item.strategicClinicalNetwork = dataItem.strategicClinicalNetwork ? dataItem.strategicClinicalNetwork : [];
        item.nationalSPORNetwork = dataItem.nationalSPORNetwork ? dataItem.nationalSPORNetwork : [];
        item.SPORSupportUnit = dataItem.SPORSupportUnit ? dataItem.SPORSupportUnit : [];
        item.EDIPriorityArea = dataItem.EDIPriorityArea ? dataItem.EDIPriorityArea : [];
        item.EDIPriorityAreaParticularGroup = dataItem.EDIPriorityAreaParticularGroup
            ? dataItem.EDIPriorityAreaParticularGroup
            : '';
        item.layAbstract = dataItem.layAbstract ? dataItem.layAbstract : '';
        item.potentialImpact = dataItem.potentialImpact ? dataItem.potentialImpact : '';
        item.supportRequestedDescription = dataItem.supportRequestedDescription
            ? dataItem.supportRequestedDescription
            : '';
        item.supportRequestedAssistance = dataItem.supportRequestedAssistance
            ? dataItem.supportRequestedAssistance
            : [];
        item.attachedDocumentsInfo = dataItem.attachedDocumentsInfo ? dataItem.attachedDocumentsInfo : [];
        item.receiveNewsletter = dataItem.receiveNewsletter ? dataItem.receiveNewsletter : '';
        item.additionalComments = dataItem.additionalComments ? dataItem.additionalComments : '';
        item.rejectionComments = dataItem.rejectionComments ? dataItem.rejectionComments : '';
        item.submittedDate = dataItem.submittedDate ? new Date(dataItem.submittedDate) : undefined;
        item.rejectedDate = dataItem.rejectedDate ? new Date(dataItem.rejectedDate) : undefined;
        item.approvedDate = dataItem.approvedDate ? new Date(dataItem.approvedDate) : undefined;
        item.notificationSendDate = dataItem.notificationSendDate ? new Date(dataItem.notificationSendDate) : undefined;
        item.Created = dataItem.Created ? new Date(dataItem.Created) : undefined;
        item.Modified = dataItem.Modified ? new Date(dataItem.Modified) : undefined;
        return item;
    }

    public static toJson(item: FormServiceRequestResource, newEntry: boolean) {
        let dataItem = {
            ...(newEntry && item.applicationId && {applicationId: item.applicationId}),
            ...(newEntry && item.amendmentId && {amendmentId: item.amendmentId}),
            ...(newEntry && item.userId && {userId: item.userId}),
            UserFirstName: item.UserFirstName,
            UserLastName: item.UserLastName,
            UserEmail: item.UserEmail,
            UserPhoneNumber: item.UserPhoneNumber,
            requesterName: item.requesterName,
            requesterPhone: item.requesterPhone,
            requesterEmail: item.requesterEmail,
            requesterInstitution: item.requesterInstitution,
            requesterDepartment: item.requesterDepartment,
            applicantName: item.applicantName,
            applicantPhone: item.applicantPhone,
            applicantEmail: item.applicantEmail,
            applicantInstitution: item.applicantInstitution,
            applicantDepartment: item.applicantDepartment,
            role: item.role,
            roleOther: item.roleOther,
            previousInteraction: item.previousInteraction,
            previousInteractionWho: item.previousInteractionWho,
            studyLongTitle: item.studyLongTitle,
            studyShortTitle: item.studyShortTitle,
            studyType: item.studyType,
            ethicsApproval: item.ethicsApproval,
            ethicsApplicationNumber: item.ethicsApplicationNumber,
            primarySite: item.primarySite,
            targetStartDate: item.targetStartDate ? item.targetStartDate.toISOString() : null,
            targetCompletionDate: item.targetCompletionDate ? item.targetCompletionDate.toISOString() : null,
            projectFunded: item.projectFunded,
            projectFundedInitiatives: item.projectFundedInitiatives,
            strategicClinicalNetwork: item.strategicClinicalNetwork,
            nationalSPORNetwork: item.nationalSPORNetwork,
            SPORSupportUnit: item.SPORSupportUnit,
            EDIPriorityArea: item.EDIPriorityArea,
            EDIPriorityAreaParticularGroup: item.EDIPriorityAreaParticularGroup,
            layAbstract: item.layAbstract,
            potentialImpact: item.potentialImpact,
            supportRequestedDescription: item.supportRequestedDescription,
            supportRequestedAssistance: item.supportRequestedAssistance,
            attachedDocumentsInfo: item.attachedDocumentsInfo,
            receiveNewsletter: item.receiveNewsletter,
            additionalComments: item.additionalComments,
            rejectionComments: item.rejectionComments,
        };
        return dataItem;
    }
}
