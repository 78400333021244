import { Label, Link } from "@fluentui/react";
import { Component } from "react";
import DataService from "../data/DataService";
import { TFormResource } from "../types/TFormResource";
import { IAttachmentDocument } from "../interfaces/IAttachmentDocument";

export interface IAttachedDocumentsProps {
  applicationName: string;
}

export interface IAttachedDocumentsState {
  AttachedDocuments: IAttachmentDocument[];
  formItem: TFormResource | undefined;
}

class AttachedDocuments extends Component<IAttachedDocumentsProps, IAttachedDocumentsState> {
  dataService: DataService = new DataService();

  constructor(props: IAttachedDocumentsProps) {
    super(props);
    this.state = {
      AttachedDocuments: [],
      formItem: undefined,
    };
  }

  private async _deleteAttachment(path: string) {
    const executed = await this.dataService.deleteFormAttachment(path);
    if (executed) {
      const attachments = this.state.AttachedDocuments.filter(attachment => attachment.path !== path);
      this.setState({ AttachedDocuments: attachments });
    }
  }

  private async _downloadAttachment(fileName: string, path: string) {
    await this.dataService.getFormAttachment(fileName, path);
  }

  public async getAttachments(formItem: TFormResource) {
    if (formItem.applicationId.length > 0) {
      const attachments = await this.dataService.getFormAttachments(this.props.applicationName, formItem);
      this.setState({ AttachedDocuments: attachments, formItem: formItem });
    }
  }

  public render() {
    const items = this.state.AttachedDocuments.map((item, index) => {
      const deleteEnabled = (this.state.formItem!.status === 'Draft') ? true : false;
      return (
        <div key={index} style={{ display: 'grid', gridTemplateColumns: 'auto 100px', gridTemplateRows: 'auto', backgroundColor: '#fff', border: '1px solid #cccccc', borderRadius: '5px', padding: '1px 10px', marginTop: '5px' }}>
          <div style={{ gridArea: 'auto / 1' }}><Link onClick={() => { this._downloadAttachment(item.filename, item.path); }}>{item.filename}</Link></div>
          {deleteEnabled ? <div style={{ gridArea: 'auto / 2', textAlign: 'right' }}><Link onClick={() => { this._deleteAttachment(item.path); }}>delete</Link></div> : null}
        </div>
      );
    });
    return (
      <div style={{ marginTop: '10px', width: '100%' }}>
        <Label>Attached Documents</Label>
        {items.length > 0 ? items : <Label>There are no documents attached</Label>}
      </div>
    );
  }
}

export default AttachedDocuments;