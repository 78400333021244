import React, { Component, createRef } from "react";
import Dropzone from "./Dropzone";
import "./Upload.scss";
import Progress from "./Progress";
import DataService from "../data/DataService";
import successUploadIcon from '../assets/baseline-check_circle_outline-24px.svg';
import failUploadIcon from '../assets/failed-24px.svg';
import { Label, Link } from "@fluentui/react";
import AttachedDocuments from "./AttachedDocuments";
import { TFormResource } from "../types/TFormResource";

export interface IUploadProps {
  applicationName: string;
  fileSizeLimit: number;
  uploadKey?: React.Key | null | undefined;
  disabled?: boolean;
}

export interface IUploadStatus {
  fileName: string;
  progress: number;
  status: string;
}

export interface IUploadState {
  files: File[];
  uploading: boolean;
  uploadProgress: IUploadStatus[];
}

class Upload extends Component<IUploadProps, IUploadState> {
  public static defaultProps = {
    disabled: false
  };

  dataService: DataService = new DataService();
  attachedDocumentsRef: React.RefObject<AttachedDocuments> = createRef<AttachedDocuments>();
  constructor(props: IUploadProps) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: [],
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  onFilesAdded(files: ConcatArray<File>, uploadProgress: ConcatArray<IUploadStatus>) {
    this.setState(prevState => ({
      files: prevState.files.concat(files),
      uploadProgress: prevState.uploadProgress.concat(uploadProgress)
    }));
  }

  public async callGetAttachments(formItem: TFormResource) {
    this.attachedDocumentsRef.current!.getAttachments(formItem);
  }

  public async uploadFiles(formItem: TFormResource) {
    this.setState({ uploading: true });
    try {
      for (const file of this.state.files) {
        await this._sendRequest(file, formItem);
      }
      this.setState({
        files: [],
        uploading: false,
        uploadProgress: [],
      });
      this.attachedDocumentsRef.current!.getAttachments(formItem);
    } catch (e) {
      this.setState({ uploading: false });
    }
  }

  private _sendRequest(file: File, formItem: TFormResource) {
    return new Promise(async (resolve, reject) => {
      const request = new XMLHttpRequest();

      request.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const uploadProgress = this.state.uploadProgress;
          for (const uploadProgressItem of uploadProgress) {
            if (uploadProgressItem.fileName === file.name) {
              uploadProgressItem.status = "pending";
              uploadProgressItem.progress = (event.loaded / event.total) * 100;
              this.setState({ uploadProgress: uploadProgress });
              break;
            }
          }
        }
      });

      request.addEventListener("loadend", event => {
        const uploadProgress = this.state.uploadProgress;
        for (const uploadProgressItem of uploadProgress) {
          if (uploadProgressItem.fileName === file.name) {
            if (request.readyState === 4 && request.status === 204) {
              uploadProgressItem.status = "done";
              uploadProgressItem.progress = 100;
              this.setState({ uploadProgress: uploadProgress });
              resolve(request);
            } else {
              uploadProgressItem.status = "error";
              uploadProgressItem.progress = 100;
              this.setState({ uploadProgress: uploadProgress });
              reject(request);
            }
            break;
          }
        }
      });

      request.upload.addEventListener("error", event => {
        const uploadProgress = this.state.uploadProgress;
        for (const uploadProgressItem of uploadProgress) {
          if (uploadProgressItem.fileName === file.name) {
            uploadProgressItem.status = "error";
            uploadProgressItem.progress = 0;
            this.setState({ uploadProgress: uploadProgress });
            reject(request);
            break;
          }
        }
      });

      await this.dataService.addFormAttachment(this.props.applicationName, formItem, file, request);
    });
  }

  private _renderProgress(file: File) {
    for (const uploadProgressItem of this.state.uploadProgress) {
      if (uploadProgressItem.fileName === file.name) {
        return (
          <div className="ProgressWrapper">
            <Progress progress={uploadProgressItem.progress} />
            <img
              className="SuccessIcon"
              alt="done"
              src={successUploadIcon}
              style={{
                display: uploadProgressItem.status === "done" ? "inline" : "none",
              }}
            />
            <img
              className="FailIcon"
              alt="error"
              src={failUploadIcon}
              style={{
                display: uploadProgressItem.status === "error" ? "inline" : "none",
              }}
            />
          </div>
        );
      }
    }
  }

  private _removeFile(file: File) {
    const files = this.state.files;
    // remove file from files
    const index = files.indexOf(file);
    files.splice(index, 1);
    // remove file from uploadProgress
    const uploadProgress = this.state.uploadProgress;
    const index2 = uploadProgress.findIndex(item => item.fileName === file.name);
    uploadProgress.splice(index2, 1);
    this.setState({ files, uploadProgress });
  }

  public render(): React.ReactElement {
    return (
      <div key={this.props.uploadKey} className="Upload">
        <AttachedDocuments
          ref={this.attachedDocumentsRef}
          applicationName={this.props.applicationName}
        />
        <Label style={{ marginTop: '10px' }}>Attach New Document(s)</Label>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || !!this.props.disabled}
              fileSizeLimit={this.props.fileSizeLimit}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">
                    {file.name}
                    <Link style={{ marginLeft: '20px' }} onClick={() => { this._removeFile(file); }}>remove</Link>
                  </span>
                  {this._renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Upload;