import React, { Component } from "react";
import "./Dropzone.scss";
import cloudUpload from '../assets/baseline-cloud_upload-24px.svg';
import { DefaultButton } from "@fluentui/react";

export interface IDropzoneProps {
  disabled: boolean;
  onFilesAdded: (files: File[], uploadProgress: IUploadStatus[]) => void;
  fileSizeLimit: number;
}

export interface IUploadStatus {
  fileName: string;
  progress: number;
  status: string;
}

export interface IDropzoneState {
  hightlight: boolean;
}

class Dropzone extends Component<IDropzoneProps, IDropzoneState> {
  fileInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: IDropzoneProps) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef<HTMLInputElement>();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current!.click();
  }

  onFilesAdded(evt: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded && files) {
      const array = this.fileListToArray(files);
      const uploadProgress: IUploadStatus[] = [];
      for (const file of array) {
        uploadProgress.push({ fileName: file.name, progress: 0, status: "pending" });
      }
      this.props.onFilesAdded(array, uploadProgress);
    }
  }

  onDragOver(event: { preventDefault: () => void; }) {
    event.preventDefault();
    if (this.props.disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event: { preventDefault: () => void; dataTransfer: { files: FileList; }; }) {
    event.preventDefault();
    if (this.props.disabled) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      const uploadProgress: IUploadStatus[] = [];
      for (const file of array) {
        uploadProgress.push({ fileName: file.name, progress: 0, status: "pending" });
      }
      this.props.onFilesAdded(array, uploadProgress);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list: FileList) {
    const array: File[] = [];
    for (var i = 0; i < list.length; i++) {
      // limit file size
      if ((list.item(i) as File).size < this.props.fileSizeLimit) {
        array.push(list.item(i) as File);
      }
    }
    return array;
  }

  render() {
    const fileSizeLimit = this.props.fileSizeLimit / 1024 / 1024;
    return (
      <div
        className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <img
          alt="upload"
          className="Icon"
          src={cloudUpload}
        />
        <span>Drag &amp; Drop Files Here</span>
        <DefaultButton
          text="or Select File(s)"
          onClick={this.openFileDialog}
        />
        <span style={{marginTop: '5px', marginBottom: '5px', fontSize: '13px'}}>(max size per file {fileSizeLimit}MB)</span>
      </div>
    );
  }
}

export default Dropzone;