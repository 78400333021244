import React, {Component} from "react";
import "./Progress.scss";

export interface IProgressProps {
  progress: number;
}

class Progress extends Component<IProgressProps> {
  constructor(props:IProgressProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="ProgressBar">
        <div
          className="Progress"
          style={{ width: this.props.progress + "%" }}
        />
      </div>
    );
  }
}

export default Progress;