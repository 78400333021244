import React, { useEffect } from 'react';
import Auth from '../../Auth';
import '../../App.scss';

const LogoutPage: React.FunctionComponent = () => {
    useEffect(() => {
        Auth.app.logoutRedirect({
            postLogoutRedirectUri: '/' // Redirect to home page after logout
        });
    }, []);

    return (
        <main className="App-navigation">
            <h1>Logging out...</h1>
        </main>
    );
}

export default LogoutPage;
