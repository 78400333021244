// for single column layout
export const TEXT_SINGLE_COLUMN = {
    root: {
        maxWidth: 800,
        width: '100%',
    },
};

// for double column layout
export const TEXT_DOUBLE_COLUMN = {
    root: {
        maxWidth: 390,
        width: '100%',
    },
};


// for double column layout (specific to ComboBox componen)
export const TEXT_DOUBLE_COLUMN_COMBOBOX = {
    container: {
        maxWidth: 390,
        width: '100%',
    },
};

// size limit for each file uploaded
export const FILE_SIZE_LIMIT: number = 31457280;

// time interval beetween form auto save
export const AUTO_SAVE_INTERVAL: number = 300000;

export const ORGANIZATION_DEFAULTS = [
    { key: '', text: '' },
    { key: 'Alberta Health Services', text: 'Alberta Health Services' },
    { key: 'Alberta Innovates', text: 'Alberta Innovates' },
    { key: 'Athabasca University', text: 'Athabasca University' },
    { key: 'Covenant Health', text: 'Covenant Health' },
    { key: 'University of Alberta', text: 'University of Alberta' },
    { key: 'University of Calgary', text: 'University of Calgary' },
    { key: 'University of Lethbridge', text: 'University of Lethbridge' },
    { key: 'Other', text: 'Other' }
];