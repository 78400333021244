import React, { createRef } from 'react';
import DataService from '../data/DataService';
import { FormLetterSupportResource } from '../dto/FormLetterSupportResource';
import { IFormMethodOutput } from '../interfaces/IFormMethodOutput';
import { IFormConfiguration } from '../interfaces/IFormConfiguration';
import { IFormState } from '../interfaces/IFormState';
import { IFormValidation } from '../interfaces/IFormValidation';
import Navigation from '../layouts/Navigation';
import { Checkbox, ChoiceGroup, DatePicker, defaultCalendarStrings, IChoiceGroupOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, IChoiceGroupOptionStyles, IRefObject, DefaultButton, DialogFooter, Dialog, DialogType, ContextualMenu, TooltipHost, Icon, ICheckboxStyleProps, ICheckboxStyles, Dropdown, ITextFieldStyles, IDropdownStyles, IChoiceGroupStyles, ITheme, IDatePickerStyles } from '@fluentui/react';
import Upload from '../components/Upload';
import ChoiceGroupWithValidation from '../components/ChoiceGroupWithValidation';
import TextFieldWithVisualLimit from '../components/TextFieldWithVisualLimit';
import Utils from '../utils/Utils';
import Auth from '../Auth';
import { RouteComponentProps } from 'react-router-dom';
import FormLogo from '../assets/AbSPORULogo.png';
import CheckboxWithValidation from '../components/CheckboxWithValidation';
import { ISelectableOptionWithTooltip } from '../interfaces/ISelectableOptionWithTooltip';
import dateFormat from 'dateformat';
import DatePickerWithValidation from '../components/DatePickerWithValidation';
import { TEXT_DOUBLE_COLUMN, TEXT_SINGLE_COLUMN, FILE_SIZE_LIMIT, AUTO_SAVE_INTERVAL, ORGANIZATION_DEFAULTS } from '../constants/Constants';

export interface IFormLetterSupportProps extends RouteComponentProps {
}

export default class FormLetterSupport extends React.Component<IFormLetterSupportProps, IFormState> {
    applicationName: string = 'LetterOfSupport';
    fileSizeLimit: number = FILE_SIZE_LIMIT;
    dataService: DataService = new DataService();
    utils: Utils = new Utils();
    pathApplicationId = (window.location.pathname.split('/').pop() as string);
    saveIntervalId: number;

    // create and set choices fields (checkbox and dropdown (ISelectableOptionWithTooltip[]), radio (IChoiceGroupOption[]))
    roleChoices: IChoiceGroupOption[] = this._setRoleChoices();
    previousInteractionChoices: IChoiceGroupOption[] = this._setPreviousInteractionChoices();
    receiveNewsletterChoices: IChoiceGroupOption[] = this._setReceiveNewsletterChoices();
    supportRequestedAssistanceChoices: ISelectableOptionWithTooltip[] = this._setSupportRequestedAssistanceChoices();
    attachedDocumentsInfoChoices: ISelectableOptionWithTooltip[] = this._setAttachedDocumentsInfoChoices();

    // create a ref for each field that can be focused. For example: On a validation error
    requesterNameRef: IRefObject<any> = createRef();
    requesterPhoneRef: IRefObject<any> = createRef();
    requesterEmailRef: IRefObject<any> = createRef();
    requesterInstitutionRef: IRefObject<any> = createRef();
    requesterDepartmentRef: IRefObject<any> = createRef();
    applicantEmailRef: IRefObject<any> = createRef();
    mailingAddressRef: IRefObject<any> = createRef();
    roleRef: IRefObject<any> = createRef();
    roleOtherRef: IRefObject<any> = createRef();
    previousInteractionWhoRef: IRefObject<any> = createRef();
    studyLongTitleRef: IRefObject<any> = createRef();
    grantDeadlineRef: IRefObject<any> = createRef();
    layAbstractRef: IRefObject<any> = createRef();
    formAgreementRef: IRefObject<any> = createRef();
    uploadRef: React.RefObject<Upload> = createRef<Upload>();

    constructor(props: IFormLetterSupportProps) {
        super(props);
        const formConfiguration: IFormConfiguration[] = [];
        const initialFormItem: FormLetterSupportResource = new FormLetterSupportResource();
        const formItem: FormLetterSupportResource = new FormLetterSupportResource();
        const formItemLastSaved: FormLetterSupportResource = new FormLetterSupportResource();
        const formValidation: IFormValidation[] = [];
        // add validation information for each field to be validated
        formValidation.push({ propertyName: 'requesterName', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterNameRef });
        formValidation.push({ propertyName: 'requesterPhone', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterPhoneRef });
        formValidation.push({ propertyName: 'requesterEmail', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.requesterEmailRef });
        formValidation.push({ propertyName: 'requesterInstitution', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterInstitutionRef });
        formValidation.push({ propertyName: 'requesterDepartment', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterDepartmentRef });
        formValidation.push({ propertyName: 'applicantEmail', isValid: true, validationType: 'custom', errorMessage: '', propertyRef: this.applicantEmailRef });
        formValidation.push({ propertyName: 'mailingAddress', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.mailingAddressRef });
        formValidation.push({ propertyName: 'role', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.roleRef });
        formValidation.push({ propertyName: 'roleOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.roleOtherRef });
        formValidation.push({ propertyName: 'previousInteractionWho', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.previousInteractionWhoRef });
        formValidation.push({ propertyName: 'studyLongTitle', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.studyLongTitleRef });
        formValidation.push({ propertyName: 'grantDeadline', isValid: true, validationType: 'custom', errorMessage: 'This date cannot be before the Date when Letter of Support is required', propertyRef: this.grantDeadlineRef });
        formValidation.push({ propertyName: 'layAbstract', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.layAbstractRef, charLimit: 1500, remainingChars: 1500 });
        formValidation.push({ propertyName: 'formAgreement', isValid: true, validationType: 'custom', errorMessage: 'You must agree to the disclaimer and the terms of use to submit your application.', propertyRef: this.formAgreementRef });

        this.state = {
            formConfiguration: formConfiguration,
            initialFormItem: initialFormItem,
            changedFields: {},
            formItem: formItem,
            formItemLastSaved: formItemLastSaved,
            formVisible: false,
            formValidation: formValidation,
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserPhoneNumber: '',
            clearDialogHidden: true,
            submitDialogHidden: true,
            amendDialogHidden: true,
            helpRequestDialogHidden: true,
            notificationDialogHidden: true,
            notificationDialogTitle: '',
            notificationDialogMessage: '',
            redirectToDashboard: false,
            formAgreement: false,
            uploadElementKey: 1,
            showRequesterInstitutionOther: false,
            showApplicantInstitutionOther: false,
            isFormDisabled: false,
            IsAmendment: false,
        };
    };

    private _onAgreementChange = (propertyName: string, ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        this.setState({
            formAgreement: checked ? true : false,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onTextFieldChange = (propertyName: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, propertyName);
        const newFormItem = { ...this.state.formItem, [propertyName]: newValue };
        const isChanged = newValue !== this.state.initialFormItem[propertyName];
        const newChangedFields = { ...this.state.changedFields, [propertyName]: isChanged };

        this.setState({
            formItem: newFormItem,
            changedFields: newChangedFields,
        });
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (newValue ? newValue.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
        this._clearErrorMessage(propertyName);
    }

    private _onChoiceGroupChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption): void => {
        const newFormItem = { ...this.state.formItem, [propertyName]: option?.key.toString() };
        const isChanged = option?.key.toString() !== this.state.initialFormItem[propertyName];
        const newChangedFields = { ...this.state.changedFields, [propertyName]: isChanged };

        this.setState({
            formItem: newFormItem,
            changedFields: newChangedFields,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _onDatePickerChange = (propertyName: string, date: Date | null | undefined): void => {
        const newFormItem = { ...this.state.formItem, [propertyName]: date };
        const isChanged = date !== this.state.initialFormItem[propertyName];
        const newChangedFields = { ...this.state.changedFields, [propertyName]: isChanged };

        this.setState({
            formItem: newFormItem,
            changedFields: newChangedFields,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onCheckBoxChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean, isYesNoCheck?: boolean): void => {
        const newOptions: string[] = [];
        const checkBoxValue: string = (ev?.target as HTMLInputElement).name;
        if (isYesNoCheck) {
            const newValue = ((this.state.formItem as any)[propertyName] === "" || (this.state.formItem as any)[propertyName] === "No") ? "Yes" : "No";
            const newFormItem = { ...this.state.formItem, [propertyName]: newValue };
            const isChanged = newValue !== this.state.initialFormItem[propertyName];
            const newChangedFields = { ...this.state.changedFields, [propertyName]: isChanged };

            this.setState({
                formItem: newFormItem,
                changedFields: newChangedFields,
            });
        } else {
            if ((this.state.formItem as any)[propertyName]) {
                (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                    newOptions.push(selectedOption);
                });
            }
            if (checked && !newOptions.includes(checkBoxValue)) {
                newOptions.push(checkBoxValue);
            } else {
                newOptions.splice(newOptions.indexOf(checkBoxValue), 1);
            }
            const newFormItem = { ...this.state.formItem, [propertyName]: newOptions };
            const isChanged = JSON.stringify(newOptions.sort()) !== JSON.stringify((this.state.initialFormItem[propertyName] || []).sort());
            const newChangedFields = { ...this.state.changedFields, [propertyName]: isChanged };

            this.setState({
                formItem: newFormItem,
                changedFields: newChangedFields,
            });
        }
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _loadCheckboxes(propertyName: string, choices: ISelectableOptionWithTooltip[], componentRef?: IRefObject<any>, width?: number) {
        const Checkboxes: JSX.Element[] = [];
        const newOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        if ((this.state.formItem as any)[propertyName]) {
            (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                newOptions.push(selectedOption);
            });
        }
        choices.forEach((choice: ISelectableOptionWithTooltip, index) => {
            let isChecked: boolean = newOptions.includes((choice.key as string)) ? true : false;
            if (choice.tooltipText) {
                Checkboxes.push(
                    <span key={index} style={{ display: 'flex' }}>
                        <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={this._getCheckboxStyles(propertyName, checkboxStyles({
                            theme: {} as ITheme,
                            isUsingCustomLabelRender: true,
                            checked: isChecked,
                        }))} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                            onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                        />
                        <TooltipHost
                            styles={{ root: { marginLeft: '8px', alignSelf: 'center' } }}
                            content={choice.tooltipText}
                            tooltipProps={{
                                styles: {
                                    content: { fontWeight: '600' }
                                }
                            }}>
                            <Icon iconName="Info" />
                        </TooltipHost>
                    </span>
                );
            } else {
                Checkboxes.push(
                    <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={this._getCheckboxStyles(propertyName, checkboxStyles({
                        theme: {} as ITheme,
                        isUsingCustomLabelRender: true,
                        checked: isChecked,
                    }))} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                        onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                    />
                );
            }
        });
        return Checkboxes;
    }

    private _setRoleChoices() {
        const choices: IChoiceGroupOption[] = [];
        const style: IChoiceGroupOptionStyles = {
            root: {
                alignItems: 'flex-start',
                width: 380,
            },
        };
        choices.push({ key: "Academic", text: "Academic", styles: style });
        choices.push({ key: "Clinician", text: "Clinician", styles: style });
        choices.push({ key: "Community/Municipal Org Rep", text: "Community/Municipal Organization Representative (including policy maker)", styles: style });
        choices.push({ key: "Federal/Provincial Rep", text: "Federal/Provincial Representative (including policy maker)", styles: style });
        choices.push({ key: "Healthcare Provider", text: "Healthcare Provider", styles: style });
        choices.push({ key: "Health System/Care Manager", text: "Health System/Care Manager", styles: style });
        choices.push({ key: "Masters Student", text: "Masters Student", styles: style });
        choices.push({ key: "PaCER", text: "Patient and Community Engagement Researcher (PaCER)", styles: style });
        choices.push({ key: "Patient Partner", text: "Patient Partner", styles: style });
        choices.push({ key: "PhD Student", text: "PhD Student", styles: style });
        choices.push({ key: "Postdoc/Fellow", text: "Postdoctoral/Fellow", styles: style });
        choices.push({ key: "Resident", text: "Resident", styles: style });
        choices.push({ key: "Undergraduate Student", text: "Undergraduate Student", styles: style });
        choices.push({ key: "Other", text: "Other (describe below)", styles: style });
        return choices;
    }

    private _setPreviousInteractionChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Yes", text: "Yes", });
        choices.push({ key: "No", text: "No", });
        return choices;
    }

    private _setSupportRequestedAssistanceChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Data & Research Services", text: "Data & Research Services (Research Design, Data Management, Data Analysis, Data Extraction & Linkages)", customLabel: <span><span style={{ fontWeight: '600' }}>Data & Research Services</span> (Research Design, Data Management, Data Analysis, Data Extraction & Linkages)</span> });
        choices.push({ key: "Patient Engagement", text: "Patient Engagement (Patient Engagement Plans, Patient Partners, Training & Mentorship, Evaluation & Certification)", customLabel: <span><span style={{ fontWeight: '600' }}>Patient Engagement</span> (Patient Engagement Plans, Patient Partners, Training & Mentorship, Evaluation & Certification)</span> });
        choices.push({ key: "Learning Health Systems", text: "Learning Health Systems (Knowledge Translation, Implementation)", customLabel: <span><span style={{ fontWeight: '600' }}>Learning Health Systems</span> (Knowledge Translation, Implementation)</span> });
        return choices;
    }

    private _setAttachedDocumentsInfoChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Research Protocol/Project Description", text: "Research Protocol/Project Description", });
        choices.push({ key: "Other Relevant Documents", text: "Other Relevant Documents", });
        return choices;
    }

    private _setReceiveNewsletterChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Yes", text: "Yes", });
        choices.push({ key: "No", text: "No", });
        return choices;
    }

    private _displayClearDialog() {
        this.setState({
            clearDialogHidden: false,
        });
    }

    private _displaySubmitDialog() {
        this.setState({
            submitDialogHidden: false,
        });
    }

    private _displayAmendDialog() {
        this.setState({
            amendDialogHidden: false,
        });
    }

    private _displayHelpDialog() {
        this.setState({
            helpRequestDialogHidden: false,
        });
    }

    private _handlePrint() {
        window.print();
    }

    private async _dismissClearDialog() {
        this.setState({
            clearDialogHidden: true
        });
    }

    private async _dismissSubmitDialog() {
        this.setState({
            submitDialogHidden: true
        });
    }

    private async _dismissAmendDialog() {
        this.setState({
            amendDialogHidden: true
        });
    }

    private async _dismissHelpDialog() {
        this.setState({
            helpRequestDialogHidden: true
        });
    }

    private async _dismissNotificationDialog() {
        if (this.state.redirectToDashboard) {
            this._clearFormTimer();
            this.props.history.push('/');
        } else {
            this.setState({
                notificationDialogHidden: true,
            });
        }
    }

    private async _getFormConfiguration() {
        const formConfiguration = await this.dataService.getFormConfiguration(this.applicationName);
        this.setState({
            formConfiguration: formConfiguration,
        });
    }

    private _calculateRemainingChars(formItem: FormLetterSupportResource) {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, 'layAbstract');
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (formItem.layAbstract ? formItem.layAbstract.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private async _getFormLetterSupport(applicationId: string) {
        const formOperationOutput = await this.dataService.getFormItem(this.applicationName, applicationId);
        let formItem = (formOperationOutput.formItem as FormLetterSupportResource);
        if (formOperationOutput.completed) {
            const changedFields = Object.keys(formItem).reduce<{ [key: string]: boolean }>((acc, key) => {
                acc[key] = false; // Initialize each field as not changed
                return acc;
            }, {});
            this._calculateRemainingChars(formItem);
            const action = this.props.location.state ? (this.props.location.state as { action?: string }).action : null;
            this.setState({
                initialFormItem: formItem,
                changedFields: changedFields,
                formItem: formItem,
                formItemLastSaved: { ...formItem },
                formVisible: true,
                isFormDisabled: action === 'view',
                showRequesterInstitutionOther: !(ORGANIZATION_DEFAULTS.some(i => i.key === formItem.requesterInstitution) || formItem.requesterInstitution === ''),
                showApplicantInstitutionOther: !(ORGANIZATION_DEFAULTS.some(i => i.key === formItem.applicantInstitution) || formItem.applicantInstitution === ''),
                formAgreement: action !== 'draft',
                IsAmendment: action === 'amend',
            });
            await this.uploadRef.current && this.uploadRef.current!.callGetAttachments(formItem);
        } else {
            alert('Could not get form data.');
            this.props.history.push('/');
        }
    }

    private _clearOtherFieldValues(propertyName: string) {
        let changed = false;
        const formItem = this.state.formItem as FormLetterSupportResource;
        switch (propertyName) {
            case "role":
                if (formItem.role !== "Other" && formItem.roleOther !== "") {
                    formItem.roleOther = "";
                    changed = true;
                }
                break;
            case "previousInteraction":
                if (formItem.previousInteraction !== "Yes" && formItem.previousInteractionWho !== "") {
                    formItem.previousInteractionWho = "";
                    changed = true;
                }
                break;
        }
        if (changed) {
            this.setState({
                formItem: formItem,
            });
        }
    }

    private _clearErrorMessage(propertyName: string) {
        const properties: string[] = [];
        properties.push(propertyName);
        switch (propertyName) {
            case "role":
                properties.push('roleOther');
                break;
            case "previousInteraction":
                properties.push('previousInteractionWho');
                break;
        }
        const formValidation = this.state.formValidation;
        let changed = false;
        properties.forEach((property) => {
            const formValidationItem = this.utils.getFormValidationItem(formValidation, property);
            if (formValidationItem && !formValidationItem.isValid) {
                formValidationItem.isValid = true;
                changed = true;
            }
        });
        if (changed) {
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private _validateForm() {
        let isValid = true;
        const formItem = this.state.formItem as FormLetterSupportResource;
        const formValidation = this.state.formValidation;
        let focusSet = false;
        let focusItem: IRefObject<any> | null = null;
        for (const formValidationItem of formValidation) {
            switch (formValidationItem.validationType) {
                case 'required':
                    if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                        isValid = false;
                        formValidationItem.isValid = false;
                        if (!focusSet) {
                            focusItem = formValidationItem.propertyRef;
                            focusSet = true;
                        }
                    }
                    break;
                case 'custom':
                    switch (formValidationItem.propertyName) {
                        case 'requesterEmail':
                            if (formItem.requesterEmail.length === 0 || !this.utils.isValidEmail(formItem.requesterEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (formItem.requesterEmail.length === 0) {
                                    formValidationItem.errorMessage = 'This field cannot be empty';
                                } else {
                                    formValidationItem.errorMessage = 'Please enter a valid email address';
                                }
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'applicantEmail':
                            if (formItem.applicantEmail.length > 0 && !this.utils.isValidEmail(formItem.applicantEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'Please enter a valid email address';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'role':
                            if (formItem.role.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'roleOther':
                            if (formItem.role === 'Other' && formItem.roleOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'previousInteractionWho':
                            if (formItem.previousInteraction === 'Yes' && formItem.previousInteractionWho.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'grantDeadline':
                            if (!formItem.letterSupportDate && formItem.grantDeadline) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'No date when Letter of Support is required informed. Please enter the date';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            if (formItem.letterSupportDate && formItem.grantDeadline && (formItem.letterSupportDate > formItem.grantDeadline)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'formAgreement':
                            if (!this.state.formAgreement) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                    }
                    break;
            }
        }
        if (!isValid) {
            this.setState({
                formValidation: formValidation,
            });
            this._dismissSubmitDialog().then(() => {
                if (focusItem) {
                    (focusItem as any).current.focus();
                }
            });
        }
        return isValid;
    }

    private async _saveFormLetterSupport(disableNotification?: boolean) {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormLetterSupportResource);
            this.setState({
                formItem: (formOperationOutput.formItem as FormLetterSupportResource),
                formItemLastSaved: { ...(formOperationOutput.formItem as FormLetterSupportResource) },
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Save Form',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nThis application is in draft form and will not be processed until it is submitted. \n\nRemember that this application is available on the dashboard. You can come back and continue completing your application later.`,
            });
        } else {
            this.setState({
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while saving the application.',
            });
        }
    }

    private async _submitFormLetterSupport() {
        if (this._validateForm()) {
            await this._saveFormLetterSupport(true);
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.submitFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this._clearFormTimer();
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Submit Application',
                        notificationDialogMessage: `This request has been completed and submitted. \n\nPlease take note of the Application ID: (${formOperationOutput.formItem?.applicationId}). Remember to check your e-mail regularly, as it will be the main source of contact during the approval process. \n\nSending this request does not guarantee its approval.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while submitting the application.',
                    });
                }
            } else {
                this.setState({
                    submitDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while submitting the application.',
                });
            }
        }
    }

    private async _amendFormLetterSupport() {
        await this._updateToken();
        const formItem = this.state.formItem;
        const amendedFormItem = new FormLetterSupportResource();
        amendedFormItem.applicationId = formItem.applicationId;
        amendedFormItem.amendmentId = formItem.amendmentId;
        amendedFormItem.edgeId = formItem.edgeId;
        amendedFormItem.userId = formItem.userId;
        amendedFormItem.status = formItem.status;

        Object.entries(formItem).forEach(([key, value]) => {
            if (value !== this.state.initialFormItem[key] && !['applicationId', 'amendmentId', 'edgeId', 'userId', 'status'].includes(key)) {
                amendedFormItem[key] = value;
            }
        });

        const formOperationOutput = await this.dataService.amendFormItem(this.applicationName, amendedFormItem);
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormLetterSupportResource);
            this._clearFormTimer();
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Amend Application',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) amended.`,
                redirectToDashboard: true,
            });
        } else {
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while amending the application.',
            });
        }
    }

    private async _helpRequestFormLetterSupport() {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        formItem.status = 'Help Requested';
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.requestHelpFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this.setState({
                        helpRequestDialogHidden: true,
                        formItem: (formOperationOutput.formItem as FormLetterSupportResource),
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Request Help',
                        notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nA case manager will contact you to help.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        helpRequestDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while requesting help.',
                    });
                }
            } else {
                this.setState({
                    helpRequestDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while requesting help.',
                });
            }
        } else {
            this.setState({
                helpRequestDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while requesting help.',
            });
        }
    }

    private async _clearFormLetterSupport() {
        await this._updateToken();
        const clearedItem = new FormLetterSupportResource();
        clearedItem.applicationId = this.state.formItem.applicationId;
        clearedItem.amendmentId = this.state.formItem.amendmentId;
        clearedItem.edgeId = this.state.formItem.edgeId;
        clearedItem.userId = this.state.formItem.userId;
        clearedItem.status = this.state.formItem.status;
        const formValidation = this.state.formValidation;
        for (const formValidationItem of formValidation) {
            formValidationItem.isValid = true;
        }
        // delete attachments if any
        if (this.state.formItem.applicationId.length > 0) {
            const attachments = await this.dataService.getFormAttachments(this.applicationName, this.state.formItem);
            for (const attachment of attachments) {
                await this.dataService.deleteFormAttachment(attachment.path);
            }
        }
        this.setState({
            formItem: clearedItem,
            formValidation: formValidation,
            ...(this.state.uploadElementKey && { uploadElementKey: this.state.uploadElementKey + 1 }),
        });
        this._dismissClearDialog().then(() => {
            if (this.requesterNameRef) {
                (this.requesterNameRef as any).current.focus();
            }
        });
    }

    private async _retrieveFormInformation() {
        await this._getUserInfo();
        await this._getFormConfiguration();
        this.saveIntervalId = this._saveFormTimer();
        if (this.pathApplicationId.length > 0 && this.pathApplicationId !== 'FormLetterSupport') {
            this._getFormLetterSupport(this.pathApplicationId);
        } else {
            if (this.state.formItem.applicationId.length === 0) {
                const formItem = this.state.formItem as FormLetterSupportResource;
                formItem.UserFirstName = this.state.UserFirstName;
                formItem.UserLastName = this.state.UserLastName;
                formItem.UserEmail = this.state.UserEmail;
                formItem.UserPhoneNumber = this.state.UserPhoneNumber;
                formItem.requesterName = this.state.UserFirstName + ' ' + this.state.UserLastName;
                formItem.requesterEmail = this.state.UserEmail;
                formItem.requesterPhone = this.state.UserPhoneNumber;
                this.setState({
                    formItem: formItem
                });
                await this._saveFormLetterSupport(true);
            }
            this.setState({
                formVisible: true
            });
        }
    }

    private async _getUserInfo() {
        const authResult = await Auth.acquireTokenSilent();
        if (authResult) {
            this.setState({
                UserFirstName: (authResult.idTokenClaims as any).given_name ? (authResult.idTokenClaims as any).given_name : '',
                UserLastName: (authResult.idTokenClaims as any).family_name ? (authResult.idTokenClaims as any).family_name : '',
                UserEmail: (authResult.idTokenClaims as any).emails[0] ? (authResult.idTokenClaims as any).emails[0] : '',
                UserPhoneNumber: (authResult.idTokenClaims as any).extension_PhoneNumber ? (authResult.idTokenClaims as any).extension_PhoneNumber : '',
            });
        }
    }

    private _clearFormTimer() {
        window.clearInterval(this.saveIntervalId);
    }

    private _saveFormTimer(): number {
        return window.setInterval(() => {
            if (this.state.formItem.status === 'Draft' && JSON.stringify(this.state.formItem) !== JSON.stringify(this.state.formItemLastSaved)) {
                this._saveFormLetterSupport(true);
            }
        }, AUTO_SAVE_INTERVAL);
    }

    private async _updateToken() {
        try {
            await this.dataService.getToken();
        } catch (error) {
            this.props.history.push('/logout');
        }
    }

    private _getTextFieldStyles = (propertyName: string, baseStyles: Partial<ITextFieldStyles> = {}): Partial<ITextFieldStyles> => {
        return {
            ...baseStyles,
            fieldGroup: [
                // Apply base styles for fieldGroup
                ...(baseStyles.fieldGroup instanceof Array ? baseStyles.fieldGroup : [baseStyles.fieldGroup]),
                // Apply backgroundColor for Amendments
                this.state.changedFields[propertyName] && this.state.IsAmendment && {
                    backgroundColor: '#fbfb98',
                },
            ],
        };
    };

    private _getDropdownStyles = (propertyName: string, baseStyles: Partial<IDropdownStyles> = {}): Partial<IDropdownStyles> => {
        return {
            ...baseStyles,
            title: [
                // Apply base styles for the title
                ...(baseStyles.title instanceof Array ? baseStyles.title : [baseStyles.title]),
                // Apply backgroundColor for Amendments
                this.state.changedFields[propertyName] && this.state.IsAmendment && {
                    backgroundColor: '#fbfb98',
                },
            ],
            dropdown: [
                // Apply base styles for the dropdown container
                ...(baseStyles.dropdown instanceof Array ? baseStyles.dropdown : [baseStyles.dropdown]),
                // Apply backgroundColor for Amendments
                this.state.changedFields[propertyName] && this.state.IsAmendment && {
                    backgroundColor: '#fbfb98',
                },
            ],
        };
    };

    private _getChoiceGroupStyles = (propertyName: string, baseStyles: Partial<IChoiceGroupStyles> = {}): Partial<IChoiceGroupStyles> => {
        return {
            ...baseStyles,
            flexContainer: [
                // Apply base styles for the flexContainer
                ...(baseStyles.flexContainer instanceof Array ? baseStyles.flexContainer : [baseStyles.flexContainer]),
                // Change background color if this field has been changed and it's an amendment
                this.state.changedFields[propertyName] && this.state.IsAmendment && {
                    backgroundColor: '#fbfb98',
                },
            ],
        };
    };

    private _getCheckboxStyles = (propertyName: string, baseStyles: Partial<ICheckboxStyles> = {}): Partial<ICheckboxStyles> => {
        return {
            ...baseStyles,
            root: [
                // Apply base styles for the root
                ...(baseStyles.root instanceof Array ? baseStyles.root : [baseStyles.root]),
                // Change background color if this field has been changed and it's an amendment
                this.state.changedFields[propertyName] && this.state.IsAmendment && {
                    backgroundColor: '#fbfb98',
                },
            ],
        };
    };

    private _getDatePickerStyles = (propertyName: string, baseStyles: Partial<IDatePickerStyles> = {}): Partial<IDatePickerStyles> => {
        // Check if the conditions are met
        const shouldApplyBackgroundColor = this.state.changedFields[propertyName] && this.state.IsAmendment;

        return {
            ...baseStyles,
            textField: {
                ...(baseStyles.textField instanceof Object ? baseStyles.textField : [baseStyles.textField]),
                ...(shouldApplyBackgroundColor && {
                    "& .ms-TextField-field": {
                        backgroundColor: "#fbfb98",
                    }
                }),
            },
        };
    };

    public componentDidMount() {
        this._retrieveFormInformation();
    }

    public render(): React.ReactElement {
        const formItem = this.state.formItem as FormLetterSupportResource;
        const { formConfiguration, formVisible, formValidation, UserFirstName, UserLastName, UserEmail, clearDialogHidden, submitDialogHidden, amendDialogHidden, helpRequestDialogHidden, notificationDialogHidden, notificationDialogTitle, notificationDialogMessage } = this.state;
        const spinner = !formVisible ? <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" /> : null;
        const supportRequestedAssistanceCheckBoxes = this._loadCheckboxes("supportRequestedAssistance", this.supportRequestedAssistanceChoices);
        const attachedDocumentsInfoCheckBoxes = this._loadCheckboxes("attachedDocumentsInfo", this.attachedDocumentsInfoChoices);
        const clearFormButton = <DefaultButton className="clearButton" text="Clear Form" disabled={this.state.isFormDisabled} onClick={this._displayClearDialog.bind(this)} />;
        const saveButton = formItem.status === 'Draft' ? <DefaultButton text="Save Form" disabled={this.state.isFormDisabled} onClick={() => this._saveFormLetterSupport()} /> : null;
        const submitButton = formItem.status === 'Draft' ? <PrimaryButton text="Submit Form" disabled={this.state.isFormDisabled} onClick={this._displaySubmitDialog.bind(this)} /> : null;
        const amendButton = (formItem.status === 'Submitted' || formItem.status === 'Rejected') ? <PrimaryButton text="Amend Form" disabled={this.state.isFormDisabled} onClick={this._displayAmendDialog.bind(this)} /> : null;
        const helpRequestButton = formItem.status === 'Draft' ? <DefaultButton className="floatHelpButton" text="Request Help" disabled={this.state.isFormDisabled} onClick={this._displayHelpDialog.bind(this)} /> : null;
        const printButton = <DefaultButton text="Print Form" onClick={this._handlePrint.bind(this)} iconProps={{ iconName: 'Print' }} />;
        return (
            <main className="App-formbody">
                <Navigation
                    title="Letter of Support Request Form"
                    isForm={true}
                    UserFirstName={UserFirstName}
                    UserLastName={UserLastName}
                    UserEmail={UserEmail}
                    clearFormTimer={this._clearFormTimer.bind(this)} />
                {spinner}
                <form style={!formVisible ? { display: 'none' } : {}}>
                    <section className="applicationIDSection">
                        <span className='col1'><h2>Application Number: {formItem.applicationId}</h2></span>
                        <span className='col2'><img src={FormLogo} alt="Form logo" /></span>
                    </section>
                    <section>
                        <h2>1. Requested by</h2>
                        <h3>Primary contact for which questions about your application will be directed to</h3>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'requesterName')}
                            label="Name"
                            componentRef={this.requesterNameRef}
                            styles={this._getTextFieldStyles('requesterName', TEXT_SINGLE_COLUMN)}
                            value={formItem.requesterName}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("requesterName", event, newValue); }}
                        />
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterPhone')}
                                label="Phone Number"
                                componentRef={this.requesterPhoneRef}
                                styles={this._getTextFieldStyles('requesterPhone', TEXT_DOUBLE_COLUMN)}
                                value={formItem.requesterPhone}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterPhone", event, newValue); }}
                            />
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterEmail')}
                                label="Email"
                                componentRef={this.requesterEmailRef}
                                styles={this._getTextFieldStyles('requesterEmail', TEXT_DOUBLE_COLUMN)}
                                value={formItem.requesterEmail}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterEmail", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }} >
                            <Dropdown
                                className='requiredField'
                                label="Institution/Organization"
                                styles={this._getDropdownStyles('requesterInstitution', TEXT_DOUBLE_COLUMN)}
                                componentRef={this.requesterInstitutionRef}
                                selectedKey={this.state.showRequesterInstitutionOther ? 'Other' : formItem.requesterInstitution}
                                options={ORGANIZATION_DEFAULTS}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, item) => {
                                    if (item?.key !== 'Other') {
                                        this._onTextFieldChange('requesterInstitution', event as any, item?.key.toString());
                                        this.setState({ showRequesterInstitutionOther: false });
                                    }
                                    else {
                                        formItem.requesterInstitution = '';
                                        this.setState({ showRequesterInstitutionOther: true, formItem });
                                    }
                                }}
                            />
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterDepartment')}
                                label="Department"
                                componentRef={this.requesterDepartmentRef}
                                styles={this._getTextFieldStyles('requesterDepartment', TEXT_DOUBLE_COLUMN)}
                                value={formItem.requesterDepartment}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterDepartment", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }}>
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterInstitution')}
                                disabled={!this.state.showRequesterInstitutionOther || this.state.isFormDisabled}
                                styles={this._getTextFieldStyles('requesterInstitution', {
                                    root: { maxWidth: 390, width: '100%', margin: '10px 0 0 0 !important' }
                                })}
                                value={this.state.showRequesterInstitutionOther ? formItem.requesterInstitution : ''}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterInstitution", event, newValue); }}
                            />
                        </Stack>
                    </section>
                    <section>
                        <h2>2. Applicant/PI</h2>
                        <h3>If different from the primary contact identified above, i.e. project sponsor, PI, who the letter should be addressed to</h3>
                        <TextField
                            label="Name"
                            styles={this._getTextFieldStyles('applicantName', TEXT_SINGLE_COLUMN)}
                            value={formItem.applicantName}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("applicantName", event, newValue); }}
                        />
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                label="Phone Number"
                                styles={this._getTextFieldStyles('applicantPhone', TEXT_DOUBLE_COLUMN)}
                                value={formItem.applicantPhone}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantPhone", event, newValue); }}
                            />
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'applicantEmail')}
                                componentRef={this.applicantEmailRef}
                                label="Email"
                                styles={this._getTextFieldStyles('applicantEmail', TEXT_DOUBLE_COLUMN)}
                                value={formItem.applicantEmail}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantEmail", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }} >
                            <Dropdown
                                label="Institution/Organization"
                                styles={this._getDropdownStyles('applicantInstitution', TEXT_DOUBLE_COLUMN)}
                                selectedKey={this.state.showApplicantInstitutionOther ? 'Other' : formItem.applicantInstitution}
                                options={ORGANIZATION_DEFAULTS}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, item) => {
                                    if (item?.key !== 'Other') {
                                        this._onTextFieldChange('applicantInstitution', event as any, item?.key.toString());
                                        this.setState({ showApplicantInstitutionOther: false });
                                    }
                                    else {
                                        formItem.applicantInstitution = '';
                                        this.setState({ showApplicantInstitutionOther: true, formItem });
                                    }
                                }}
                            />
                            <TextField
                                label="Department"
                                styles={this._getTextFieldStyles('applicantDepartment', TEXT_DOUBLE_COLUMN)}
                                value={formItem.applicantDepartment}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantDepartment", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }}>
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'applicantInstitution')}
                                disabled={!this.state.showApplicantInstitutionOther || this.state.isFormDisabled}
                                styles={this._getTextFieldStyles('applicantInstitution', {
                                    root: { maxWidth: 390, width: '100%', margin: '10px 0 0 0 !important' }
                                })}
                                value={this.state.showApplicantInstitutionOther ? formItem.applicantInstitution : ''}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantInstitution", event, newValue); }}
                            />
                        </Stack>
                    </section>
                    <section>
                        <h2 className='requiredItem'>3. Mailing Address</h2>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'mailingAddress')}
                            ariaLabel="Mailing Address"
                            componentRef={this.mailingAddressRef}
                            styles={this._getTextFieldStyles('mailingAddress', TEXT_SINGLE_COLUMN)}
                            value={formItem.mailingAddress}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("mailingAddress", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>4. Select the role that best applies to the Applicant/PI</h2>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.role}
                            onChange={(event, option) => { this._onChoiceGroupChange("role", event, option); }}
                            options={this.roleChoices}
                            componentRef={this.roleRef}
                            disabled={this.state.isFormDisabled}
                            styles={this._getChoiceGroupStyles('role', {
                                flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(7, auto)" }
                            })}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'role')}
                        />
                        <TextField
                            disabled={formItem.role !== 'Other' || this.state.isFormDisabled}
                            styles={this._getTextFieldStyles('roleOther', {
                                root: { maxWidth: 400, width: '100%', }
                            })}
                            ariaLabel="Other Role description"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'roleOther')}
                            componentRef={this.roleOtherRef}
                            value={formItem.roleOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("roleOther", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>5. Has there been a previous interaction with AbSPORU regarding this project?</h2>
                        <ChoiceGroup
                            selectedKey={formItem.previousInteraction}
                            styles={this._getChoiceGroupStyles('previousInteraction')}
                            onChange={(event, option) => { this._onChoiceGroupChange("previousInteraction", event, option); }}
                            options={this.previousInteractionChoices}
                            disabled={this.state.isFormDisabled}
                        />
                        <Label>If yes, who did you speak with?</Label>
                        <Label style={{ margin: 0, marginTop: '-10px', fontSize: '0.875rem', color: '#8a8a8a', fontWeight: 400 }}>Please provide the name of the person you previously spoke with or any relevant request numbers (i.e. EDGE ID, AHS RMT)</Label>
                        <TextField
                            disabled={formItem.previousInteraction !== 'Yes' || this.state.isFormDisabled}
                            styles={this._getTextFieldStyles('previousInteractionWho', TEXT_SINGLE_COLUMN)}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'previousInteractionWho')}
                            componentRef={this.previousInteractionWhoRef}
                            value={formItem.previousInteractionWho}
                            onChange={(event, newValue) => { this._onTextFieldChange("previousInteractionWho", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>6. Study Titles</h2>
                        <TextField
                            className='requiredField'
                            label="Study Long Title"
                            styles={this._getTextFieldStyles('studyLongTitle', TEXT_SINGLE_COLUMN)}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyLongTitle')}
                            componentRef={this.studyLongTitleRef}
                            multiline={true}
                            rows={5}
                            value={formItem.studyLongTitle}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyLongTitle", event, newValue); }}
                        />
                        <Label>Study Short Title</Label>
                        <Label style={{ margin: 0, marginTop: '-10px', fontSize: '0.875rem', color: '#8a8a8a', fontWeight: 400 }}>If available, use the short title entered in the ethics application or (Sponsor Initials) ProtocolNumber_ShortTitle/Acronym</Label>
                        <TextField
                            styles={this._getTextFieldStyles('studyShortTitle', TEXT_SINGLE_COLUMN)}
                            multiline={true}
                            rows={5}
                            value={formItem.studyShortTitle}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyShortTitle", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>7. Please list the PRIMARY SITE the research is taking place</h2>
                        <TextField
                            styles={this._getTextFieldStyles('primarySite', TEXT_SINGLE_COLUMN)}
                            ariaLabel="Primary Site"
                            value={formItem.primarySite}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("primarySite", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>8. Date indication</h2>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <Label>Date when Letter of Support is required</Label>
                            <DatePicker
                                showGoToToday
                                ariaLabel='Date when Letter of Support is required'
                                styles={this._getDatePickerStyles('letterSupportDate', { root: { maxWidth: 390, width: '100%' } })}
                                strings={defaultCalendarStrings}
                                disabled={this.state.isFormDisabled}
                                value={!formItem.letterSupportDate ? undefined : formItem.letterSupportDate}
                                formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy"); }}
                                onSelectDate={(date) => { this._onDatePickerChange("letterSupportDate", date); }}
                            />
                            <div style={{ width: '100%', marginLeft: '20px' }}>
                                <Label>Grant Deadline</Label>
                                <DatePickerWithValidation
                                    showGoToToday
                                    styles={this._getDatePickerStyles('grantDeadline', { root: { maxWidth: 390, width: '100%' } })}
                                    ariaLabel='Grant Deadline'
                                    strings={defaultCalendarStrings}
                                    errorMessage={this.utils.getErrorMessage(formValidation, 'grantDeadline')}
                                    componentRef={this.grantDeadlineRef}
                                    value={!formItem.grantDeadline ? undefined : formItem.grantDeadline}
                                    disabled={this.state.isFormDisabled}
                                    formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy"); }}
                                    onSelectDate={(date) => { this._onDatePickerChange("grantDeadline", date); }}
                                />
                            </div>
                        </Stack>
                        <Label>(Please note that a minimum of 2 weeks time is needed to process letters of support. We cannot guarantee the completion of the letter if applications are submitted less than 2 weeks prior to the required deadline)</Label>
                    </section>
                    <section>
                        <h2>9. For each grant application, please indicate the grant name and the institution awarding the grant</h2>
                        <TextField
                            styles={this._getTextFieldStyles('grantNameInstitution', TEXT_SINGLE_COLUMN)}
                            ariaLabel="Grant Name and Institution Awarding the Grant"
                            value={formItem.grantNameInstitution}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("grantNameInstitution", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>10. Lay Abstract</h2>
                        <TextFieldWithVisualLimit
                            className='requiredField'
                            labelText="Describe the research in plain language suitable for someone with no experience in your research area."
                            styles={this._getTextFieldStyles('layAbstract', TEXT_SINGLE_COLUMN)}
                            ariaLabel="Lay Abstract"
                            multiline={true}
                            rows={5}
                            value={formItem.layAbstract}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("layAbstract", event, newValue); }}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'layAbstract')}
                            charLimit={this.utils.getFormValidationItem(formValidation, 'layAbstract').charLimit as number}
                            remainingChars={this.utils.getFormValidationItem(formValidation, 'layAbstract').remainingChars as number}
                            componentRef={this.layAbstractRef}
                        />
                    </section>
                    <section>
                        <h2>11. AbSPORU Support Requested</h2>
                        <Label>Provide a detailed description of the support you require</Label>
                        <Label style={{ margin: 0, marginTop: '-10px', fontSize: '0.875rem', color: '#8a8a8a', fontWeight: 400 }}>Please be as detailed as possible so that we can accurately outline the support services that can be provided. For more information about the services provided, please visit our website: <a href='https://absporu.ca/research-services/' target="_blank" rel="noreferrer">https://absporu.ca/research-services/</a></Label>
                        <TextField
                            styles={this._getTextFieldStyles('supportRequestedDescription', TEXT_SINGLE_COLUMN)}
                            multiline={true}
                            rows={5}
                            value={formItem.supportRequestedDescription}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("supportRequestedDescription", event, newValue); }}
                        />
                        <br />
                        <Label>Which AbSPORU support(s) do you require assistance with?</Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {supportRequestedAssistanceCheckBoxes}
                        </Stack>
                    </section>
                    <section>
                        <h2>12. Project Documents</h2>
                        <Label>Please attach the available project document(s)</Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {attachedDocumentsInfoCheckBoxes}
                        </Stack>
                        <Upload
                            uploadKey={this.state.uploadElementKey}
                            ref={this.uploadRef}
                            disabled={this.state.isFormDisabled}
                            applicationName={this.applicationName}
                            fileSizeLimit={this.fileSizeLimit} />
                    </section>
                    <section>
                        <h2>13. AbSPORU Communications</h2>
                        <Label>Would you like to receive news, updates and opportunities from the Alberta SPOR SUPPORT Unit?</Label>
                        <ChoiceGroup
                            selectedKey={formItem.receiveNewsletter}
                            styles={this._getChoiceGroupStyles('receiveNewsletter')}
                            onChange={(event, option) => { this._onChoiceGroupChange("receiveNewsletter", event, option); }}
                            options={this.receiveNewsletterChoices}
                            disabled={this.state.isFormDisabled}
                        />
                    </section>
                    <section>
                        <h2>14. Additional Information</h2>
                        <TextField
                            label="Additional Comments/Notes"
                            styles={this._getTextFieldStyles('additionalComments', TEXT_SINGLE_COLUMN)}
                            multiline={true}
                            rows={5}
                            value={formItem.additionalComments}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("additionalComments", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h1>Disclaimer</h1>
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUseTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUse')}</Label>
                        <br />
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarrantyTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarranty')}</Label>
                        <CheckboxWithValidation
                            label={"I Agree"}
                            componentRef={this.formAgreementRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'formAgreement')}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, checked) => { this._onAgreementChange("formAgreement", event, checked); }}
                            checked={this.state.formAgreement}
                        />
                        <br />
                        <Label>If you have questions or concerns about any collection, use or disclosure of information by EDGE Alberta, please contact the AbSPORU Case Manager, by email at <a href='mailto:absporu@albertainnovates.ca'>absporu@albertainnovates.ca</a></Label>
                        {helpRequestButton}
                        <div style={{ display: 'grid', gridTemplateColumns: '115px 140px auto 130px', gridTemplateRows: 'auto', marginTop: '15px' }}>
                            <div style={{ gridArea: '1 / 1 / 1 / 1' }}>
                                {clearFormButton}
                            </div>
                            <div style={{ gridArea: '1 / 2 / 1 / 2', marginLeft: '10px' }}>
                                {saveButton}
                                {amendButton}
                            </div>
                            <div style={{ gridArea: '1 / 4 / 1 / 4', textAlign: 'right' }}>
                                {submitButton}
                            </div>
                            <div style={{ gridArea: '1 / 5 / 1 / 5', marginLeft: '10px', textAlign: 'right' }}>
                                {printButton}
                            </div>
                        </div>
                    </section>
                    <Dialog
                        className='renderLinefeed'
                        hidden={clearDialogHidden}
                        onDismiss={() => { this._dismissClearDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Clear Form',
                            subText: 'Are you sure you want to clear the form? \nThis will remove all entered data.',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissClearDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._clearFormLetterSupport(); }} text="Yes, Clear Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={submitDialogHidden}
                        onDismiss={() => { this._dismissSubmitDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Submit application',
                            subText: `Are you sure you want to submit this application ${formItem.applicationId.length > 0 ? '(' + formItem.applicationId + ')' : ''}?`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissSubmitDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._submitFormLetterSupport(); }} text="Yes, Submit Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={amendDialogHidden}
                        onDismiss={() => { this._dismissAmendDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Amend Application',
                            subText: `You are about to modify your submitted application ${formItem.applicationId}. \nPlease select "Yes, Amend Application" to proceed with your changes.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissAmendDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._amendFormLetterSupport(); }} text="Yes, Amend Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={helpRequestDialogHidden}
                        onDismiss={() => { this._dismissHelpDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Request Help',
                            subText: `Do you want to save the form as is and request help from the case manager to complete filling the form? Please complete as much fields as possible before requesting help.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissHelpDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._helpRequestFormLetterSupport(); }} text="Yes, request for help" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={notificationDialogHidden}
                        onDismiss={() => { this._dismissNotificationDialog(); }}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: notificationDialogTitle,
                            subText: notificationDialogMessage,
                        }}
                    />
                </form>
            </main>
        );
    }
}