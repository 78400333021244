import React from 'react';
import { InteractionType, RedirectRequest } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import Auth from '../../Auth';
import { Header } from '../../layouts/Header';
import { Footer } from '../../layouts/Footer';
import { useHistory } from 'react-router-dom';

function ErrorComponent(props: any) {
    return <p>Error: {props.error.message}</p>;
}

const LoginPage: React.FunctionComponent = () => {
    const history = useHistory();
    const request: RedirectRequest = {
        scopes: Auth.scopes,
        redirectStartPage: '/' // redirect to home page after login
    };

    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        history.push('/');
        return null;
    } else {
        return (
            <div className="App">
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={request}
                    // Optional components, see here for details: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#msalauthenticationtemplate-component
                    errorComponent={ErrorComponent}
                //loadingComponent={LoadingComponent}
                >
                    <div className="App">
                        <Header />
                        <main className="App-formbody">
                            <div>Redirecting to login</div>
                        </main>
                        <Footer />
                    </div>
                </MsalAuthenticationTemplate>
            </div>
        );
    }
}

export default LoginPage;
