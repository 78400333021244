import { DatePicker, IDatePickerProps } from "@fluentui/react";
import { Component } from "react";
import "./ErrorMessage.scss";

export interface IDatePickerWithValidationProps extends IDatePickerProps {
    errorMessage: string;
}

class DatePickerWithValidation extends Component<IDatePickerWithValidationProps> {
    render() {
        return (
            <div>
                <DatePicker {...this.props} />
                <div role="alert">
                    <p className="ms-TextField-errorMessage" style={{paddingTop: '0', marginTop: '-3px'}}>
                        <span data-automation-id="error-message">{this.props.errorMessage}</span>
                    </p>
                </div>
            </div>
        );
    }
}

export default DatePickerWithValidation;