import { DefaultButton, Link } from '@fluentui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface INavigationProps {
    title: string;
    isForm: boolean;
    UserFirstName: string;
    UserLastName: string;
    UserEmail: string;
    clearFormTimer?(): void;
}

export const Navigation: React.FunctionComponent<INavigationProps> = (props: INavigationProps) => {
    const history = useHistory();
    return (
        <nav className="App-navigation">
            <div style={{ marginBottom: 30, display: 'grid', gridTemplateColumns: 'auto 350px 700px auto', alignItems: 'end' }}>
                <span style={{ gridArea: '1 / 1' }}></span>
                <span style={{ gridArea: '1 / 2' }}>
                    {props.isForm ? <span><Link onClick={() => { props.clearFormTimer && props.clearFormTimer(); history.push('/'); }}>Dashboard &gt;</Link></span> : null}
                    {props.isForm ? <span className='current' >Application Form</span> : null}
                </span>
                <span style={{ gridArea: '1 / 3', justifySelf: 'end' }}>
                    <label>{props.UserFirstName} {props.UserLastName} ({props.UserEmail})</label>
                    <Link style={{marginLeft: '-13px', marginRight: '10px'}} onClick={() => { editProfile(); }}>Edit profile</Link>
                    <span><DefaultButton text="Logout" onClick={() => { props.clearFormTimer && props.clearFormTimer(); history.push('/logout'); }} /></span>
                </span>
                <span style={{ gridArea: '1 / 4' }}></span>
            </div>
            {props.title.length > 0 ? <h1>{props.title}</h1> : null}
        </nav>
    );
}

export default Navigation;

function editProfile() {
    let url = process.env.REACT_APP_AUTH_EDIT_PROFILE!;
    url = url.replace(/&redirect_uri=.*?%2Flogin&scope=/, `&redirect_uri=${encodeURIComponent(window.location.origin)}%2Flogin&scope=`);
    window.location.href = url;
}
