import {IFormConfiguration} from '../interfaces/IFormConfiguration';
import {IFormValidation} from '../interfaces/IFormValidation';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

export default class Utils {
    public getConfigurationValue(formConfiguration: IFormConfiguration[], propertyName: string): string {
        return formConfiguration[formConfiguration.findIndex((x) => x.property === propertyName)]
            ? formConfiguration[formConfiguration.findIndex((x) => x.property === propertyName)].value
            : '';
    }

    public getErrorMessage(formValidation: IFormValidation[], propertyName: string): string {
        const formValidationItem = this.getFormValidationItem(formValidation, propertyName);
        return formValidationItem && formValidationItem.isValid ? '' : formValidationItem && formValidationItem.errorMessage ? formValidationItem.errorMessage : '';
    }

    public getFormValidationItem(formValidation: IFormValidation[], propertyName: string): IFormValidation {
        return formValidation[formValidation.findIndex((x) => x.propertyName === propertyName)];
    }

    public isValidEmail(email: string): boolean {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public parseHTML = (htmlString: string) => {
        const cleanHtmlString = DOMPurify.sanitize(htmlString,
          { USE_PROFILES: { html: true } });
        const html = parse(cleanHtmlString);
        return html;
    }
}
