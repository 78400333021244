import {Form} from './Form';

export class FormCovenantHealthRequestResource extends Form {
    public UserFirstName: string = '';
    public UserLastName: string = '';
    public UserEmail: string = '';
    public UserPhoneNumber: string = '';
    public studyTitle: string = '';
    public studyReviewedBy: string = '';
    public studyEthicsBoardNumber: string = '';
    public PIName: string = '';
    public PIPhoneNumber: string = '';
    public PICovenantHealthPrivileges: string = '';
    public PIEmail: string = '';
    public PIUniversityAffiliation: string = '';
    public researchCoordinatorName: string = '';
    public researchCoordinatorEmail: string = '';
    public researchCoordinatorPhoneNumber: string = '';
    public studyLocationsParticipating: string[] = [];
    public studyLocationsOther: string = '';
    public studyLocationsOtherDescription: string = '';
    public studyLocationsDepartments: string[] = [];
    public studyLocationsDepartmentsOther: string = '';
    public projectStartDate: Date | undefined = undefined;
    public projectCompletionDate: Date | undefined = undefined;
    public studyDatabases: string[] = [];
    public studyDatabasesOther: string = '';
    public projectInvolveChartReview: string = '';
    public projectChartReviewer: string = '';
    public projectChartNumberReviews: string = '';
    public projectChartDiagnosesProcedures: string = '';
    public projectChartTimePeriod: string = '';
    public databasePurposeColumnA: string = '';
    public databasePurposeColumnAOther: string = '';
    public databasePurposeColumnB: string = '';
    public researchContext: string = '';
    public researchFocusPerspective: string = '';
    public serviceArea: string = '';
    public therapeuticDepartment: string = '';
    public therapeuticDepartmentSector: string = '';
    public therapeuticDepartmentSectorOther: string = '';
    public databasePurposeDisciplines: string[] = [];
    public strategicObjectives: string[] = [];

    public static fromJson(dataItem: any) {
        let item = new FormCovenantHealthRequestResource(dataItem.applicationId);
        item.amendmentId = dataItem.amendmentId ? dataItem.amendmentId : '';
        item.edgeId = dataItem.edgeId ? dataItem.edgeId : '';
        item.userId = dataItem.userId ? dataItem.userId : '';
        item.status = dataItem.status ? dataItem.status : '';
        item.UserFirstName = dataItem.UserFirstName ? dataItem.UserFirstName : '';
        item.UserLastName = dataItem.UserLastName ? dataItem.UserLastName : '';
        item.UserEmail = dataItem.UserEmail ? dataItem.UserEmail : '';
        item.UserPhoneNumber = dataItem.UserPhoneNumber ? dataItem.UserPhoneNumber : '';
        item.studyTitle = dataItem.studyTitle ? dataItem.studyTitle : '';
        item.studyReviewedBy = dataItem.studyReviewedBy ? dataItem.studyReviewedBy : '';
        item.studyEthicsBoardNumber = dataItem.studyEthicsBoardNumber ? dataItem.studyEthicsBoardNumber : '';
        item.PIName = dataItem.PIName ? dataItem.PIName : '';
        item.PIPhoneNumber = dataItem.PIPhoneNumber ? dataItem.PIPhoneNumber : '';
        item.PICovenantHealthPrivileges = dataItem.PICovenantHealthPrivileges
            ? dataItem.PICovenantHealthPrivileges
            : '';
        item.PIEmail = dataItem.PIEmail ? dataItem.PIEmail : '';
        item.PIUniversityAffiliation = dataItem.PIUniversityAffiliation ? dataItem.PIUniversityAffiliation : '';
        item.researchCoordinatorName = dataItem.researchCoordinatorName ? dataItem.researchCoordinatorName : '';
        item.researchCoordinatorEmail = dataItem.researchCoordinatorEmail ? dataItem.researchCoordinatorEmail : '';
        item.researchCoordinatorPhoneNumber = dataItem.researchCoordinatorPhoneNumber
            ? dataItem.researchCoordinatorPhoneNumber
            : '';
        item.studyLocationsParticipating = dataItem.studyLocationsParticipating
            ? dataItem.studyLocationsParticipating
            : [];
        item.studyLocationsOther = dataItem.studyLocationsOther ? dataItem.studyLocationsOther : '';
        item.studyLocationsOtherDescription = dataItem.studyLocationsOtherDescription
            ? dataItem.studyLocationsOtherDescription
            : '';
        item.studyLocationsDepartments = dataItem.studyLocationsDepartments ? dataItem.studyLocationsDepartments : [];
        item.studyLocationsDepartmentsOther = dataItem.studyLocationsDepartmentsOther
            ? dataItem.studyLocationsDepartmentsOther
            : '';
        item.projectStartDate = dataItem.projectStartDate ? new Date(dataItem.projectStartDate) : undefined;
        item.projectCompletionDate = dataItem.projectCompletionDate
            ? new Date(dataItem.projectCompletionDate)
            : undefined;
        item.studyDatabases = dataItem.studyDatabases ? dataItem.studyDatabases : [];
        item.studyDatabasesOther = dataItem.studyDatabasesOther ? dataItem.studyDatabasesOther : '';
        item.projectInvolveChartReview = dataItem.projectInvolveChartReview ? dataItem.projectInvolveChartReview : '';
        item.projectChartReviewer = dataItem.projectChartReviewer ? dataItem.projectChartReviewer : '';
        item.projectChartNumberReviews = dataItem.projectChartNumberReviews ? dataItem.projectChartNumberReviews : '';
        item.projectChartDiagnosesProcedures = dataItem.projectChartDiagnosesProcedures
            ? dataItem.projectChartDiagnosesProcedures
            : '';
        item.projectChartTimePeriod = dataItem.projectChartTimePeriod ? dataItem.projectChartTimePeriod : '';
        item.databasePurposeColumnA = dataItem.databasePurposeColumnA ? dataItem.databasePurposeColumnA : '';
        item.databasePurposeColumnAOther = dataItem.databasePurposeColumnAOther
            ? dataItem.databasePurposeColumnAOther
            : '';
        item.databasePurposeColumnB = dataItem.databasePurposeColumnB ? dataItem.databasePurposeColumnB : '';
        item.researchContext = dataItem.researchContext ? dataItem.researchContext : '';
        item.researchFocusPerspective = dataItem.researchFocusPerspective ? dataItem.researchFocusPerspective : '';
        item.serviceArea = dataItem.serviceArea ? dataItem.serviceArea : '';
        item.therapeuticDepartment = dataItem.therapeuticDepartment ? dataItem.therapeuticDepartment : '';
        item.therapeuticDepartmentSector = dataItem.therapeuticDepartmentSector
            ? dataItem.therapeuticDepartmentSector
            : '';
        item.therapeuticDepartmentSectorOther = dataItem.therapeuticDepartmentSectorOther
            ? dataItem.therapeuticDepartmentSectorOther
            : '';
        item.databasePurposeDisciplines = dataItem.databasePurposeDisciplines
            ? dataItem.databasePurposeDisciplines
            : [];
        item.strategicObjectives = dataItem.strategicObjectives ? dataItem.strategicObjectives : [];
        item.additionalComments = dataItem.additionalComments ? dataItem.additionalComments : '';
        item.rejectionComments = dataItem.rejectionComments ? dataItem.rejectionComments : '';
        item.submittedDate = dataItem.submittedDate ? new Date(dataItem.submittedDate) : undefined;
        item.rejectedDate = dataItem.rejectedDate ? new Date(dataItem.rejectedDate) : undefined;
        item.approvedDate = dataItem.approvedDate ? new Date(dataItem.approvedDate) : undefined;
        item.Created = dataItem.Created ? new Date(dataItem.Created) : undefined;
        item.Modified = dataItem.Modified ? new Date(dataItem.Modified) : undefined;
        return item;
    }

    public static toJson(item: FormCovenantHealthRequestResource, newEntry: boolean) {
        let dataItem = {
            ...(newEntry && item.applicationId && {applicationId: item.applicationId}),
            ...(newEntry && item.amendmentId && {amendmentId: item.amendmentId}),
            ...(newEntry && item.userId && {userId: item.userId}),
            UserFirstName: item.UserFirstName,
            UserLastName: item.UserLastName,
            UserEmail: item.UserEmail,
            UserPhoneNumber: item.UserPhoneNumber,
            studyTitle: item.studyTitle,
            studyReviewedBy: item.studyReviewedBy,
            studyEthicsBoardNumber: item.studyEthicsBoardNumber,
            PIName: item.PIName,
            PIPhoneNumber: item.PIPhoneNumber,
            PICovenantHealthPrivileges: item.PICovenantHealthPrivileges,
            PIEmail: item.PIEmail,
            PIUniversityAffiliation: item.PIUniversityAffiliation,
            researchCoordinatorName: item.researchCoordinatorName,
            researchCoordinatorEmail: item.researchCoordinatorEmail,
            researchCoordinatorPhoneNumber: item.researchCoordinatorPhoneNumber,
            studyLocationsParticipating: item.studyLocationsParticipating,
            studyLocationsOther: item.studyLocationsOther,
            studyLocationsOtherDescription: item.studyLocationsOtherDescription,
            studyLocationsDepartments: item.studyLocationsDepartments,
            studyLocationsDepartmentsOther: item.studyLocationsDepartmentsOther,
            projectStartDate: item.projectStartDate ? item.projectStartDate.toISOString() : null,
            projectCompletionDate: item.projectCompletionDate ? item.projectCompletionDate.toISOString() : null,
            studyDatabases: item.studyDatabases,
            studyDatabasesOther: item.studyDatabasesOther,
            projectInvolveChartReview: item.projectInvolveChartReview,
            projectChartReviewer: item.projectChartReviewer,
            projectChartNumberReviews: item.projectChartNumberReviews,
            projectChartDiagnosesProcedures: item.projectChartDiagnosesProcedures,
            projectChartTimePeriod: item.projectChartTimePeriod,
            databasePurposeColumnA: item.databasePurposeColumnA,
            databasePurposeColumnAOther: item.databasePurposeColumnAOther,
            databasePurposeColumnB: item.databasePurposeColumnB,
            researchContext: item.researchContext,
            researchFocusPerspective: item.researchFocusPerspective,
            serviceArea: item.serviceArea,
            therapeuticDepartment: item.therapeuticDepartment,
            therapeuticDepartmentSector: item.therapeuticDepartmentSector,
            therapeuticDepartmentSectorOther: item.therapeuticDepartmentSectorOther,
            databasePurposeDisciplines: item.databasePurposeDisciplines,
            strategicObjectives: item.strategicObjectives,
            additionalComments: item.additionalComments,
            rejectionComments: item.rejectionComments,
        };
        return dataItem;
    }
}
